/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* angular swiper */
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/navigation';
@import '~swiper/scss/zoom';

.theme-color{
    --ion-color-primary: var(--c);
    --ion-color-primary-rgb: var(--r, --g, --b);
    --ion-color-primary-contrast: var(--c);
    --ion-color-primary-contrast-rgb: var(--r, --g, --b);
    --ion-color-primary-shade: var(--c);
    --ion-color-primary-tint: var(--c);
}

.theme-color ion-item{
    --ion-color-primary: var(--c);
    --ion-color-primary-rgb: var(--r, --g, --b);
    --ion-color-primary-contrast: var(--c);
    --ion-color-primary-contrast-rgb: var(--r, --g, --b);
    --ion-color-primary-shade: var(--c);
    --ion-color-primary-tint: var(--c);
}

.ion-color-theme{
    --ion-color-base: #3880ff;
    --ion-color-base-rgb: 56, 128, 255;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #3171e0;
    --ion-color-tint: #4c8dff;
}

* {
    user-select: none;
  }
  *::selection {
    background: none;
  }
  *::-moz-selection {
    background: none;
  }


  .full-size {
    width: 100%;
    height: 100%;
  }

  .full-size-center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

/* Chrome, Safari, Edge y Opera */
ion-input[type="number"] input::-webkit-outer-spin-button,
ion-input[type="number"] input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
ion-input[type="number"] input[type="number"] {
    -moz-appearance: textfield;
}

/* Internet Explorer 10+ */
ion-input[type="number"] input[type="number"] {
    -ms-appearance: textfield;
}

/* Para navegadores basados en WebKit (Chrome, Safari, Opera) */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Para Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Para todos los navegadores (como un fallback) */
input[type=number] {
    appearance: textfield;
}